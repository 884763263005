import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import {
  changeStatusOrder,
  getAllOrderByUser,
  printInvoiceOrder,
  printInvoiceShippment,
  refundOrderItem,
} from "../../redux/actions/orderAction";
import notify from "../useNotification";

const UserGetOrdersHook = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [loadingPrint, setloadingPrint] = useState(true);
  const [loadingPrintShippment, setloadingPrintShippment] = useState(true);

  const [result, setResult] = useState(0);
  const [noPages, setNoPages] = useState(0);
  const [orderData, setOrderData] = useState([]);

  const [description, setDescription] = useState("");
  const [show, setShow] = useState(false);
  const [orderItemId, setOrderItemId] = useState("");
  const [loadingRefund, setLoadingRefund] = useState(true);
  const onChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleCloseModel = () => {
    setShow(false);
  };

  const user = JSON.parse(localStorage.getItem("user"));
  let username = "";
  if (user != null) {
    username = user.firstname + " " + user.lastname;
  }
  const get = async () => {
    setLoading(true);
    await dispatch(getAllOrderByUser(user._id, 5));
    setLoading(false);
  };
  const onPress = async (page) => {
    setLoading(true);
    await dispatch(getAllOrderByUser(user._id, 5, page));
    setLoading(false);
  };

  useEffect(() => {
    // console.log("successHook");
    // console.log(searchParams.get("success"));
    // console.log(localStorage.getItem("order_id"))
    if (searchParams.get("success") == 'true') {
      // console.log("trueeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
      dispatch(
        changeStatusOrder(localStorage.getItem("order_id"), { isPaid: true })
      );
      // console.log("wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww");

    }
    get();

  }, []);
  const res = useSelector((state) => state.orderReducer.ordersByUser);

  useEffect(() => {
    if (loading === false) {
      if (res && res.result) {
        setResult(res.result);
      }
      if (res && res.numberOfPages) {
        setNoPages(res.numberOfPages);
      }
      if (res && res.data) {
        setOrderData(res.data);
      }
    }
  }, [loading]);

  const clickHandler = async (id) => {
    setloadingPrint(true);
    await dispatch(printInvoiceOrder(id));
    setloadingPrint(false);
  };

  const handlePrintShipments = async (id) => {
    setloadingPrintShippment(true);
    await dispatch(printInvoiceShippment(id));
    setloadingPrintShippment(false);
  };
  const printShippment = useSelector(
    (state) => state.orderReducer.printShippment
  );

  useEffect(() => {
    if (loadingPrintShippment === false) {
      // console.log(printShippment.status)
      if (printShippment.status == 500) {
        return notify("من فضلك عدل الحاله", "warn");
      } else {
        window.open(printShippment, "_blank");
        return () => { }
      }
    }
  }, [loadingPrintShippment]);

  const printInvoice = useSelector((state) => state.orderReducer.printInvoice);

  useEffect(() => {
    if (loadingPrint === false) {
      if (printInvoice) {
        window.open(printInvoice, "_blank");
      }
    }
  }, [loadingPrint]);

  const showModalReturnProduct = (id) => {
    setOrderItemId(id);
    setShow(true);
  };

  const saveReturnProduct = async () => {
    // console.log(orderItemId)
    // console.log(description)
    setLoadingRefund(true);
    await dispatch(refundOrderItem(orderItemId, { reasonRefund: description }));
    setLoadingRefund(false);
  };

  const refundOrderRes = useSelector(
    (state) => state.orderReducer.refundOrderItem
  );

  useEffect(() => {
    if (loadingRefund === false) {
      if (refundOrderRes) {
        setShow(false);
        notify("تم طلب الإسترجاع للمنتج بنجاح", "success");
        window.location.reload();
      }
    }
  }, []);

  return [
    username,
    result,
    orderData,
    noPages,
    onPress,
    clickHandler,
    handlePrintShipments,
    user,
    showModalReturnProduct,
    description,
    show,
    onChangeDescription,
    handleCloseModel,
    saveReturnProduct,
  ];
};

export default UserGetOrdersHook;
