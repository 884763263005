import React from "react";
import SideBar from "../../Components/Admin/SideBar";
import AdminProductsFromDropShipping from "../../Components/Admin/AdminProductsFromDropShipping";

const AdminProductsFromDropShippingPage = () => {

    //const { t } = useTranslation();
  return (
    <div className="d-flex justify-content-start">
    <div>
      <SideBar />
    </div>
    <div className="p-4" style={{ width: '100%' }}>
     <AdminProductsFromDropShipping />
    </div>
  </div>
      );
};

export default AdminProductsFromDropShippingPage;

